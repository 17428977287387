export function getAssetPrefixEnvVarName() {
  return 'APPLICATION_ASSETPREFIX' as const;
}

/**
 * A function that returns the asset prefix for the application.
 *
 * the variable access declaration must not be composed as webpack will replace it with the actual value
 */
export function getAssetPrefixFromEnv() {
  if (typeof process === 'undefined') {
    return '';
  }

  const prefix = process.env.APPLICATION_ASSETPREFIX || '';
  return prefix;
}

/**
 * A function that returns the url with the asset prefix
 */
export function createUrlWithPublicPrefix(prefix: string = '') {
  return (url: string) => {
    const toRelativeUrl = url.replace(/^\//, '');
    return [prefix, toRelativeUrl].join('/');
  };
}

export const urlWithPublicPrefix = createUrlWithPublicPrefix(
  getAssetPrefixFromEnv()
);
