import React, { useCallback } from 'react';
import Head from 'next/head';

import type { createStaticNextjsConfigStore } from './createStaticNextjsConfigStore';
export function DeploymentMetaTags({
  prefix = 'reckon',
  config,
}: {
  prefix?: string;
  config: ReturnType<typeof createStaticNextjsConfigStore>;
}) {
  const key = useCallback(
    (name: string) => {
      return `${prefix}.${name}`;
    },
    [prefix]
  );

  return (
    <Head>
      <script
        id="deployment-config"
        dangerouslySetInnerHTML={{
          __html: `window.${prefix} = ${JSON.stringify(config)}`,
        }}
      />
      <meta name={key('environment')} content={config.STAGE} />
      <meta name={key('version')} content={config.APPLICATION_VERSION} />
      <meta name={key('commit')} content={config.GIT_HASH} />
      <meta name={key('commitdate')} content={config.GIT_COMMITDATE} />
      <meta name={key('releasetag')} content={config.GIT_TAG} />
    </Head>
  );
}
