import { getAssetCacheBust } from './getAssetCacheBustEnvVarName';

const OBVIOUSLY_FAKE_HOSTNAME = 'http://obviouslyfakehostname';
const OBVIOUSLY_FAKE_HOSTNAME_HOSTNAME = new URL(OBVIOUSLY_FAKE_HOSTNAME)
  .hostname;
function parseUrl(url: string) {
  // does the url have a valid protocol pattern? in it?
  const hasHostname = PROTO_PATTERN.test(url);

  try {
    const result = hasHostname
      ? new URL(url)
      : new URL(url, OBVIOUSLY_FAKE_HOSTNAME);

    return result;
  } catch (error) {
    console.error('Error parsing URL', url, error);
    return url;
  }
}
const PROTO_PATTERN = /(.*):\/\//i;

/**
 * A function that returns the url with the asset prefix
 *
 * @see getAssetCacheBust
 */
export function urlWithCacheBust(url: string) {
  const cachebust = getAssetCacheBust();
  if (!cachebust) {
    return url;
  }

  const result = parseUrl(url);
  if (typeof result === 'string') {
    return result;
  }

  // add the cachebust as a querystring
  const params = new URLSearchParams(result.search);
  params.append('cachebust', cachebust);
  result.search = params.toString();

  // because the url was actually passed in as a pathname,
  // we need to remove the fake hostname that was added
  // and return the url as a pathname (but with the cachebust)
  if (result.hostname === OBVIOUSLY_FAKE_HOSTNAME_HOSTNAME) {
    return result.toString().replace(OBVIOUSLY_FAKE_HOSTNAME, '');
  }

  // return the url as a string
  return result.toString();
}
