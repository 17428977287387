import React from 'react';

import { urlWithCacheBust } from './urlWithCacheBust';

/**
 * If you use this clientside, then you MUST
 * ensure that `getAssetCacheBust` resolves
 * to a value.
 */
export function StaticNextjsConfigLoader({
  assetPrefix = '',
}: {
  assetPrefix?: string;
}) {
  return (
    <>
      <script
        src={urlWithCacheBust(`${assetPrefix}/public/config.default.js`)}
      />
      <script
        src={urlWithCacheBust(`${assetPrefix}/public/config.generated.js`)}
      />
      <script
        src={urlWithCacheBust(`${assetPrefix}/public/config.environment.js`)}
      />
      <script
        src={urlWithCacheBust(`${assetPrefix}/public/config.runtime.js`)}
      />
      <script src={urlWithCacheBust(`${assetPrefix}/public/config.local.js`)} />
    </>
  );
}
