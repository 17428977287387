import type { ZodTypeAny } from 'zod';
import { z } from 'zod';

/**
 * Takes a string as an input and ensures that it
 * can be a comma separated list of values.
 *
 * You can use this to further refine the values:
 *
 * @example
 *
 *  const ValidTLDSchema = z.union([
 *    z.literal('One.com'),
 *    z.literal('Two.com'),
 *    z.literal('Five.com'),
 *  ]);
 *
 *  module.exports = {
 *    APPLICATION_STAGE: ArrayOfURLs(ValidTLDSchema).default('One.com,Two.com'),
 *  }
 *
 */
export function StringToArray<T extends ZodTypeAny>(schema: T) {
  return z
    .union([z.string(), z.array(z.string())])
    .transform((value) => {
      if (Array.isArray(value)) {
        return value;
      }

      return value.split(',').map((item) => {
        return item.trim();
      });
    })
    .pipe(z.array(schema));
}

function stringToURL(item: string) {
  if (item.startsWith('http')) {
    return new URL(item);
  }

  return new URL(`http://${item}`);
}
export function ArrayOfURLs<T extends ZodTypeAny>(schema: T) {
  return StringToArray(schema)
    .refine(
      (value) => {
        return value.some(stringToURL);
      },
      {
        message: 'Must be a list of valid URLs',
      }
    )
    .transform((value) => {
      return value.map(stringToURL);
    });
}
