import { createStaticNextjsConfigStore } from '@reckon-web/next-config/client';

// @ts-ignore
import ConfigEnvSchema from '../../../config.schema';
import { enable, logger } from '../../core/logger';

enable('platform:config-schema');
const mergedConfig = createStaticNextjsConfigStore(
  ConfigEnvSchema,
  logger.extend('config-schema')
) as import('../../../__generated__/config').ConfigEnv;

export const Config = mergedConfig;
