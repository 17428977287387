const { z, HumanisedBooleanLike } = require('@reckon-web/next-config/client');

/**
 * @type {import('./__generated__/config').ConfigEnv}
 */
module.exports = {
  APPLICATION_DISPLAY_NAME: z.string().default('Reckon platform'),
  APPLICATION_LOCALSTORAGE_PREFIX: z.string().default('platform_'),

  APPLICATION_SENTRY_DSN: z
    .string()
    .default(
      'https://37b9c2b4c5aea0ceca93b156223c434e@o378482.ingest.sentry.io/4505949187866624'
    ),
  APPLICATION_GOOGLE_GTM_APIKEY: z.string().default(''),
  APPLICATION_GOOGLE_GOOGLE_ANALYTICS_KEY: z.string().default(''),
  APPLICATION_HEAPIO_APPID: z.string().default(''),
  APPLICATION_BRANCHIO_APIKEY: z.string().default(''),
  APPLICATION_INTERCOM_APPID: z.string().default(''),
  APPLICATION_APPCUES_APPID: z.string().default(''),

  APPLICATION_INTERCOM_APPNAME: z.string().default('PLATFORM_WEB'),
  APPLICATION_GENERAL_HELPARTICLE_URL: z
    .string()
    .default('https://help.reckon.com/'),
  APPLICATION_CONTACT_SUPPORT_URL: z
    .string()
    .default('https://www.reckon.com/au/contact-us/'),
  APPLICATION_HELP_ARTICLE_URL: z
    .string()
    .default(
      'https://help.reckon.com/article/9g4mga8s9o-single-touch-payroll-overview#step_by_step_guide'
    ),

  APPLICATION_FEATURE_USE_MARKINGS: HumanisedBooleanLike.default(true),
  APPLICATION_FEATURE_USE_DEVLAUNCHER: HumanisedBooleanLike.default(false),

  APPLICATION_RECKON_LOGIN_URL: z
    .string()
    .default('https://genesis.dev.reckoncloud.com.au/'),
  APPLICATION_PLATFORM_CONFIRM_URL: z
    .string()
    .default(
      'https://platform.dev.reckoncloud.com.au/payroll-migration-confirm/'
    ),
  APPLICATION_OAUTH_CLIENTID: z.string().default('3le7rop14eg5i3kgsvbn23finq'),
  APPLICATION_LOGIN_URL: z
    .string()
    .default('https://reckon-auth.dev.reckoncloud.com.au/'),
  APPLICATION_APOLLO_CLIENT_API_URL: z
    .string()
    .default('https://graphql.dev.reckonapi.com/graphql'),
  APPLICATION_PAYROLLPREMIERLOGO_IMAGEURL: z
    .string()
    .default(
      'https://images.dev.reckoncloud.com.au/images/ReckonPayrollApplicationLogo.svg'
    ),
  APPLICATION_FAVICON_URL: z
    .string()
    .default(
      'https://images.dev.reckoncloud.com.au/images/ReckonReckonOneApplicationLogo.svg'
    ),
  APPLICATION_LOGO_URL: z
    .string()
    .default(
      'https://images.dev.reckoncloud.com.au/images/ReckonPlatformApplicationLogo.svg'
    ),
  APPLICATION_BOOK_CREATION_WIZARD_ID: z
    .string()
    .default('book-creation-wizard'),

  APPLICATION_RECKON_LOGO_URL: z
    .string()
    .default(
      'https://images.dev.reckoncloud.com.au/images/ReckonReckonApplicationLogo.svg'
    ),
  APPLICATION_ABOUT_BUSINESS_LEARN_MORE: z
    .string()
    .default(
      'https://business.gov.au/planning/new-businesses/business-names-trading-names-and-legal-names'
    ),

  APPLICATION_SUPPORT_PHONE_NUMBER: z.string().default('1300 756 663'),
  APPLICATION_WHITELISTED_DOMAINS: z
    .array(z.string())
    .default([
      'dev.reckoncloud.com.au',
      'reckoncloud.com.au',
      'reckonone.com',
      'stg.deadlydigits.com.au',
      'apps.reckon.com',
      'app.deadlydigits.com.au',
      'reckon.com',
      'localhost',
    ]),
  APPLICATION_PERMISSION_HELP_ARTICLE_URL: z
    .string()
    .default(
      'https://help.reckon.com/article/zqu9cvq2x1-kba-5613-creating-a-new-role-for-a-user-in-reckon-one'
    ),
  APPLICATION_BOOK_SWITCHER_URL: z
    .string()
    .default('https://reckonone.dev.reckoncloud.com.au/books/index.html'),

  APPLICATION_SUPPORT_URL: z
    .string()
    .default('https://www.reckon.com/au/support/'),
  APPLICATION_GOOGLE_TAGID: z.string().default('GTM-TLRWFL5'),
  APPLICATION_RECKON_ONE_URL: z
    .string()
    .default('https://reckonone.dev.reckoncloud.com.au/'),
  APPLICATION_MARKETING_COOKIE_NAME: z.string().default('bundle'),
};
