import type { ReactNode } from 'react';
import React, { createContext, useContext, useMemo } from 'react';
import {
  useElevationModifier,
  useMaxElevationModifier,
} from '@balance-web/elevate';
import { typedEntries } from '@balance-web/utils';

import type { BalanceTheme, BalanceThemeRaw, PaletteSignature } from './types';

// theme context

type ThemeContextType = {
  theme: BalanceTheme;
  themeRaw: BalanceThemeRaw;
  themeCSSVars: Record<string, string>;
  palette: BalanceTheme['palette'];
  paletteResolver: PaletteSignature;
};

export const ThemeContext = createContext<ThemeContextType | null>(null);

export type ThemeProviderProps = {
  theme: BalanceTheme;
  themeRaw: BalanceThemeRaw;
  themeCSSVars: Record<string, any>;
  paletteResolver?: PaletteSignature;
  children: ReactNode;
};

const useThemeContext = () => {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw Error('Theme context not found');
  }

  return themeContext;
};

export const ThemeProvider = (props: ThemeProviderProps) => {
  const resolvedPalette = props.paletteResolver
    ? props.paletteResolver(props.theme.colors)
    : props.theme.palette;

  theme = props.theme;
  themeRaw = props.themeRaw;

  return (
    <ThemeContext.Provider
      value={{
        theme: props.theme,
        themeRaw: props.themeRaw,
        themeCSSVars: props.themeCSSVars,
        palette: resolvedPalette,
        paletteResolver:
          props.paletteResolver ||
          (() => {
            return props.theme.palette;
          }),
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): BalanceTheme => {
  const { palette, theme } = useThemeContext();
  const relativeElevation = useRelativeElevation();

  return {
    ...theme,
    elevation: relativeElevation,
    palette,
  };
};

export const useRawTheme = () => {
  const { themeRaw, themeCSSVars } = useThemeContext();
  const relativeElevation = useRelativeElevation();

  return { ...themeRaw, elevation: relativeElevation, themeCSSVars };
};

const useRelativeElevation = () => {
  const { themeRaw } = useThemeContext();
  const elevationModifier = useElevationModifier();
  const maxElevationModifier = useMaxElevationModifier();

  const elevation = useMemo(() => {
    // Be mindful elements that require relative vs absolute elevation when making changes here.
    const relativeElevationTokens = {
      card: themeRaw.elevation.card + elevationModifier,
      dropdown: themeRaw.elevation.dropdown + elevationModifier,
      modal: themeRaw.elevation.modal + elevationModifier,
      popover: themeRaw.elevation.popover + elevationModifier,
      sticky: themeRaw.elevation.sticky + elevationModifier,
    };

    // Toasts are rendered outside of React component tree and always shown on top so they require absolute elevation.
    const absoluteElevationTokens = {
      toast: themeRaw.elevation.toast + maxElevationModifier,
    };

    const modifiedElevation = {
      ...relativeElevationTokens,
      ...absoluteElevationTokens,
    };

    return typedEntries(modifiedElevation).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as Record<keyof typeof modifiedElevation, number>);
  }, [
    elevationModifier,
    maxElevationModifier,
    themeRaw.elevation.card,
    themeRaw.elevation.dropdown,
    themeRaw.elevation.modal,
    themeRaw.elevation.popover,
    themeRaw.elevation.sticky,
    themeRaw.elevation.toast,
  ]);

  return elevation;
};

export let theme = {} as Omit<BalanceTheme, 'elevation'>;
export let themeRaw = {} as Omit<BalanceThemeRaw, 'elevation'>;
