export function getAssetCacheBustEnvVarName() {
  return 'APPLICATION_ASSETCACHEBUST' as const;
}
/**
 * A function that returns the asset prefix for the application.
 *
 * the variable access declaration must not be destructured. If it is destructured
 * webpack/swc/vite/nextjs will not be able to replace it with the actual value.
 */

export function getAssetCacheBust() {
  const value =
    typeof process === 'undefined'
      ? ''
      : process.env.APPLICATION_ASSETCACHEBUST || '';
  return value;
}
